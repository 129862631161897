import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'AlarmSystem',
        component: () => import('../src/components/AlarmSystem'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../src/components/Login'),
    },
    {
        path: '/',
        name: 'Main',
        component: () => import('../src/components/Main'),
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('../src/components/Home'),
            },
            {
                path: '/eventcenter',
                name: 'EventCenter',
                component: () => import('../src/components/EventCenter')
            },
            {
                path: '/statistics',
                name: 'Statistics',
                component: () => import('../src/components/Statistics')
            },
            {
                path: '/devicemanagement',
                name: 'DeviceManagement',
                component: () => import('../src/components/DeviceManagement')
            },
            {
                path: '/ykcenter',
                name: 'YKcenter',
                component: () => import('../src/components/YKCenter')
            },
            {
                path: '/page1',
                name: 'Page1',
                component: () => import('../src/components/PageOther')
            },
            {
                path: '/page2',
                name: 'Page2',
                component: () => import('../src/components/PageOther')
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    routes

})

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
    } else {
        let Authorization = localStorage.getItem('Authorization');
        if (Authorization === null || Authorization === '') {
            next('/login');
        } else {
            next();
        }
    }
});
export default router